import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { apiURL } from "../constants/constants";
import { getUserToken } from "../constants/utils";
import BankDetails from "./dashboard_tabs/BankDetails";
import BodyDetails from "./dashboard_tabs/BodyDetails";
import BookCall from "./dashboard_tabs/BookCall";
import ContactDetails from "./dashboard_tabs/ContactDetails";
import GeneralDetails from "./dashboard_tabs/GeneralDetails";
import Overview from "./dashboard_tabs/Overview";
import SocialDetails from "./dashboard_tabs/SocialDetails";
import SocialOverview from "./dashboard_tabs/SocialOverview";
import UploadID from "./dashboard_tabs/UploadID";
import UploadPhotos from "./dashboard_tabs/UploadPhotos";

export default function DashboardMain({ setTab, tab='overview' }) {
    const [userProfile, setUserProfile] = useState(null);

    useEffect(() => {
        const userToken = getUserToken();

        fetch(`${apiURL}/dashboard/user-profile/`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + userToken,
            },
        })
        .then(resp => resp.json())
        .then(data => {
            //console.log(data);
            setUserProfile(data[0]);
        })
    }, [])

    return (
        <main className="dashboardMain">
            {
                {
                    overview: <Overview setTab={setTab} />,
                    bookCall: <BookCall userProfile={userProfile} />,
                    generalDetails: <GeneralDetails setTab={setTab} />,
                    contactDetails: <ContactDetails setTab={setTab} />,
                    socialDetails: <SocialDetails setTab={setTab} />,
                    bodyDetails: <BodyDetails setTab={setTab} />,
                    bankDetails: <BankDetails setTab={setTab} />,
                    uploadID: <UploadID setTab={setTab} />,
                    uploadPhotos: <UploadPhotos setTab={setTab} />,
                }[tab]
            }
        </main>
    );
}