import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiURL } from "../../constants/constants";
import { getUserToken } from "../../constants/utils";
import BottomNavButton from "../BottomNavButton";
import ButtonSubmit from "../ButtonSubmit";
import Loader from "../Loader";
import MessageDisplay from "../MessageDisplay";
import TextInput from "../TextInput";

export default function ContactDetails({ setTab }) {
    const navigate = useNavigate();
    
    const [email, setEmail] = useState('');
    const [emailRepeat, setEmailRepeat] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState({type:'', body:''});
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userToken = getUserToken();

        fetch(`${apiURL}/dashboard/user-profile/`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + userToken,
            },
        })
        .then(resp => {
            if (resp.status == 401) {
                navigate('/logout');
            }
            return resp.json();
        })
        .then(data => {
            //console.log(data);
            const userProfile = data[0];
            setDisabled(userProfile.edit_allowed ? false : true);
            setEmail(userProfile.contact_info?.email);
            setPhone(userProfile.contact_info?.phone);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [])

    function handleSubmit(e) {
        e.preventDefault();

        if (email != emailRepeat) {
            setMessage({type:'warning', body:"Emails Don't Match"});
            return;
        }

        const userToken = getUserToken();
        const data = {
            email: email,
            phone: phone,
        }
        console.log(data);
        console.log('Token ' + userToken);

        fetch(`${apiURL}/dashboard/user-contact-info/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + userToken
            },
            body: JSON.stringify(data)
        })
        .then(resp => {
            if (!resp.ok) {
                throw new Error("Submission Failed");
            }
            return resp.json();
        })
        .then(data => {
            console.log(data);
            setMessage({type:'success', body:'Submission Successful'});
        })
        .catch((error) => {
            console.error('Error:', error);
            setMessage({type:'warning', body:'Submission Failed'});
        });
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            <h2>Contact Details</h2>
            <div>
                <form method="POST" onSubmit={(e) => handleSubmit(e)}>
                    <TextInput
                        name={'email'}
                        placeholder={'Email'}
                        setter={setEmail}
                        value={email}
                        disabled={disabled}
                    />
                    <TextInput
                        name={'email_repeat'}
                        placeholder={'Confirm Email'}
                        setter={setEmailRepeat}
                        value={emailRepeat}
                        disabled={disabled}
                    />
                    <TextInput
                        name={'phone'}
                        placeholder={'Phone'}
                        setter={setPhone}
                        value={phone}
                        disabled={disabled}
                    />
                    
                    <MessageDisplay message={message} />
                    <ButtonSubmit handleClick={handleSubmit} disabled={disabled} />
                </form>
                <div className="bottomNavBtns">
                    <BottomNavButton label={'Back'} tab={'generalDetails'} />
                    <BottomNavButton label={'Next'} tab={'socialDetails'} />
                </div>
            </div>
        </div>
    );
}