import { useNavigate } from "react-router-dom";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DashboardHeader() {
  const navigate = useNavigate();

  return (
    <header className="header">
        <img className="logo" alt="Logo" src={require("../assets/images/cropped-Lustre-MGMT-Colour.png")} />
          <p className="headerRight" onClick={() => navigate("/logout")}>
                Logout
                <span style={{ marginLeft: '0.5em' }}>
                    <FontAwesomeIcon icon={faRightFromBracket} />
                </span>
            </p>
    </header>
  );
}
