import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ButtonSubmit({ handleClick, disabled }) {
    return (
        <div style={{width:'100%'}}>
            <button
                className="dropDown button"
                onClick={handleClick}
                style={{
                    width: '95%',
                    margin: 'auto',
                    display: 'block'
                }}
                disabled={disabled}
            >
                Submit
                <span style={{marginLeft: '0.6em'}}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                </span>
            </button>
        </div>
    );
}