import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiURL } from "../../constants/constants";
import { getUserToken } from "../../constants/utils";
import SocialOverviewItem from "../SocialOverviewItem";
import SocialOverviewModal from "../SocialOverviewModal";
import Loader from "../Loader";
import { Icon } from "@iconify/react";
import { prettyDOM } from "@testing-library/react";

export default function SocialStats() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [currStats, setCurrStats] = useState();
  const [prevStats, setPrevStats] = useState();

  const statsTitles = {
    monthly_earnings: 'Monthly Earnings',
    total_followers: 'Total Followers',
    total_earnings: 'Total Earnings',
    active_subscriber_count: 'Active Subscribers',
    twitter_follower_count: 'Twitter Followers',
    instagram_follower_count: 'Instagram Followers',
    onlyfans_follower_count: 'Onlyfans Followers',
    jff_follower_count: 'JFF Followers'
  };

  function sortByDate(arr1, arr2) {
    var date1 = new Date(arr1.date);
    var date2 = new Date(arr2.date);

    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    const userToken = getUserToken();

    fetch(`${apiURL}/dashboard/user-profile/`, {
      method: "GET",
      headers: {
        Authorization: "Token " + userToken,
      },
    })
      .then((resp) => {
        if (resp.status == 401) {
          navigate("/logout");
        }
        return resp.json();
      })
      .then((data) => {
        const sortedStatsArr = [...data[0].stats].sort(sortByDate);
        setCurrStats(sortedStatsArr[sortedStatsArr.length - 1]);
        setPrevStats(sortedStatsArr[sortedStatsArr.length - 2])
        setLoading(false);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  function percentageDiff(curr, prev) {
    // calculate percentage increase
    return ((curr - prev) / prev) * 100;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="socialStats flex-container" style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
      {Object.keys(currStats).map((key, index) => {
        return (
          <div>
          {key !== "date" &&
            <>
              <div key={index} className="grid-item" style={{minWidth: '25vw'}}>
                <p>
                  <div className="card">
                    <div className="row flex-container title">
                      <div className="percentagediff row flex-container">
                        <h2 >{["total_earnings", "monthly_earnings"].includes(key) && '£'}{currStats[key]}</h2>
                        {/**percentageDiff(currStats[key], prevStats[key]) > 0 ? (
                          <Icon
                            icon="ic:round-arrow-drop-up"
                            color="#47d627"
                            width="32"
                            height="32"
                          />
                        ) : (
                          <Icon
                            icon="material-symbols:arrow-drop-down-rounded"
                            color="red"
                            width="32"
                            height="32"
                          />
                        )*/}
                        <h5 style={{ textAlign: 'center', marginTop: '-5px', color: percentageDiff(currStats[key], prevStats[key]) < 0 ? 'rgb(133 0 0)' : 'green' }}>
                          {percentageDiff(currStats[key], prevStats[key]).toFixed(1)}
                          %
                        </h5>                        
                      </div>
                      <div>
                        <h3>{statsTitles[key]}</h3>
                      </div>
                    </div>
                  </div>
                </p>
              </div>
            </>
          }</div>
        )
      })}
    </div>
  );
}