import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiURL } from "../../constants/constants";
import { getUserToken, validateFile } from "../../constants/utils";
import BottomNavButton from "../BottomNavButton";
import ButtonSubmit from "../ButtonSubmit";
import FileInput from "../FileInput";
import Loader from "../Loader";

export default function UploadPhotos({ setTab }) {
    const navigate = useNavigate();
    const formEl = useRef();
    
    const [photoFace, setPhotoFace] = useState();
    const [photoBody, setPhotoBody] = useState();
    const [photoFrontal, setPhotoFrontal] = useState();
    const [photoAdditional, setPhotoAdditional] = useState();
    const [photoFaceExists, setPhotoFaceExists] = useState();
    const [photoBodyExists, setPhotoBodyExists] = useState();
    const [photoFrontalExists, setPhotoFrontalExists] = useState();
    const [photoAdditionalExists, setPhotoAdditionalExists] = useState();

    const [disabled, setDisabled] = useState(false);
    const [message, setMessage] = useState({type:'', body:''});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userToken = getUserToken();

        fetch(`${apiURL}/dashboard/user-profile/`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + userToken,
            },
        })
        .then(resp => {
            if (resp.status == 401) {
                navigate('/logout');
            }
            return resp.json();
        })
        .then(data => {
            //console.log(data);
            const userProfile = data[0];
            setDisabled(userProfile?.edit_allowed ? false : true);
            setPhotoFaceExists(userProfile.has_photo_face ? true : false);
            setPhotoBodyExists(userProfile.has_photo_body ? true : false);
            setPhotoFrontalExists(userProfile.has_photo_frontal ? true : false);
            setPhotoAdditionalExists(userProfile.has_photo_additional ? true : false);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [])

    async function handleSubmit(e) {
        e.preventDefault();

        const maxSize = 10000000; //10MB
        const minWidth = 500; //px
        const acceptedTypes = ['image/jpeg', 'image/png'];

        for (let file of [photoFace, photoBody, photoFrontal, photoAdditional]) {
            if (!file) {
                continue;
            }
            let validationResult = await validateFile(file, acceptedTypes, maxSize, minWidth);
            if (validationResult != 'success') {
                setMessage({type:'warning', body:validationResult});
                return;
            };
        }

        const userToken = getUserToken();
        const data = {
            photoFace: photoFace,
            photoBody: photoBody,
            photoFrontal: photoFrontal,
            photoAdditional: photoAdditional
        }

        const formData = new FormData();
        formData.append('photo_face', photoFace);
        formData.append('photo_body', photoBody);
        formData.append('photo_frontal', photoFrontal);
        formData.append('photo_additional', photoAdditional);

        setMessage({type:'', body:'Uploading...'});
        fetch(`${apiURL}/dashboard/user-upload-photos/`, {
            method: 'POST',
            headers: {
                Authorization: 'Token ' + userToken
            },
            body: formData
        })
        .then(resp => {
            if (!resp.ok) {
                throw new Error('Upload Failed');
            }
            return resp.json();
        })
        .then(data => {
            //console.log(data);
            setMessage({type:'success', body:'Upload successful'});
        })
        .catch((error) => {
            console.error('Error:', error);
            setMessage({type:'success', body:'Upload Failed'});
        });
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            <h2>Upload Photos</h2>
            <div>
                <form ref={formEl} method="POST" onSubmit={(e) => handleSubmit(e)}>

                    <p className="message-warning">
                        {photoFaceExists||photoBodyExists||photoFrontalExists||photoAdditionalExists ?
                        "If you submit new photos, your existing ones will be overwritten."
                        :
                        ""
                    }</p>

                    <FileInput
                        name={'photo_face'}
                        title={'Face Photo'}
                        file={photoFace}
                        setFile={setPhotoFace}
                        accept={'image/*'}
                        className={'inputImg'}
                        disabled={disabled}
                    />

                    <FileInput
                        name={'photo_body'}
                        title={'Body Photo'}
                        file={photoBody}
                        setFile={setPhotoBody}
                        accept={'image/*'}
                        className={'inputImg'}
                        disabled={disabled}
                    />

                    <FileInput
                        name={'photo_frontal'}
                        title={'Frontal Photo'}
                        file={photoFrontal}
                        setFile={setPhotoFrontal}
                        accept={'image/*'}
                        className={'inputImg'}
                        disabled={disabled}
                    />

                    <FileInput
                        name={'photo_additional'}
                        title={'Additional Photo'}
                        file={photoAdditional}
                        setFile={setPhotoAdditional}
                        accept={'image/*'}
                        className={'inputImg'}
                        disabled={disabled}
                    />

                    <p className={message.type == 'warning' ? 'message-warning': ''}>{message.body}</p>
                    <ButtonSubmit handleClick={handleSubmit} disabled={disabled} />
                </form>

                <div className="bottomNavBtns">
                    <BottomNavButton label={'Back'} tab={'uploadID'} />
                    <BottomNavButton label={'Next'} tab={'bookCall'} />
                </div>
            </div>
        </div>
    );
}