import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("userToken");
        sessionStorage.removeItem("userToken");
        navigate('/login');
    }, []);

    return (
        <div>

        </div>
    );
}