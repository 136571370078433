import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiURL } from "../constants/constants";
import PasswordInput from "./PasswordInput";

export default function SignupForm() {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordValid, setPasswordValid] = useState(false);
    const [privacyCheck, setPrivacyCheck] = useState(false);
    const [message, setMessage] = useState({type:'', body:''});
  

    useEffect(() => {
        const passwordMinLength = 16;
        const uppercaseRE = /[A-Z]/;
        const lowercaseRE = /[a-z]/;
        const digitRE = /[0-9]/;
        
        if (password != passwordRepeat) {
            // Confirm pws match
            setPasswordValid(false);
            setPasswordError('Passwords do not match');
        } else if (password.length < passwordMinLength) {
            // Confirm pw long enough
            setPasswordValid(false);
            setPasswordError('Password must have at least ' + passwordMinLength + ' characters');
        } else if (!uppercaseRE.test(password) || !lowercaseRE.test(password) || !digitRE.test(password)) {
            // Confirm pw has uppercase, lowercase, digit
            setPasswordValid(false);
            setPasswordError('Password must contain a lowercase letter, an uppercase letter, and a digit');
        } else {
            setPasswordValid(true);
            setPasswordError('');
        }
    }, [password, passwordRepeat])

  function handleSubmit(e) {
    e.preventDefault();

    if (!passwordValid) {
        console.log("password not valid");
        return;
    }

    //localStorage.setItem('userToken', '123456789');
    //navigate('/dashboard');

    if (!privacyCheck) {
        setMessage({type:'warning', body:'Please confirm you agree to our privacy policy'});
        return;
    }

    const body = {
      username: username,
      email: email,
      password: password,
    };
    console.log(body);

    setMessage({type:'', body:'Submitting...'});
    fetch(`${apiURL}/users/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
    .then((resp) => {
        if (!resp.ok) {
            throw new Error('Submission Failed');
        }
        return resp.json()
    })
    .then((data) => {
        console.log(data);
        setMessage({type:'', body:'Submitted Successfully'});
        navigate("/dashboard");
    })
    .catch((error) => {
        console.error('Error:', error);
        setMessage({type:'warning', body:'Submission Failed'});
    });
  }

  return (
    <div className="authPage">
        <img
            className="logo"
            src={require("../assets/images/cropped-Lustre-MGMT-Colour.png")}
        />
        <h1>Sign Up</h1>
        <form className="authForm" onSubmit={(e) => handleSubmit(e)}>
            <div className="textField">
                <input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                />
            </div>
            <div className="textField">
                <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="password textField">
                <PasswordInput
                    setPassword={setPassword}
                    name={"password"}
                    placeholder={'Password'}
                />
            </div>
            <div className="password textField">
                <PasswordInput
                    setPassword={setPasswordRepeat}
                    name={"password-repeat"}
                    placeholder={'Confirm Password'}
                />
            </div>
            <small style={{color: 'red'}}>{(password || passwordRepeat) && passwordError}</small>

            <div className="checkbox">
                <input type="checkbox" name="policy" id="policy" onChange={()=>setPrivacyCheck(!privacyCheck)} />
                <label htmlFor="policy">
                    I have read and agree to the <a
                        className="link" href="https://lustremgmt.com/privacy-policy/" target='_blank'
                    >
                         privacy policy
                     </a>
                </label>
            </div>

            <p className={message.type == 'warning' ? 'message-warning' : ''}>{message.body}</p>
            <input className="button" type="submit" value="Sign Up" />
        </form>

        <p className="noAccountContainer">
            Already have an account?
            <br />
            <a className="link" onClick={() => navigate('/login')}>Login</a>
        </p>
    </div>
  );
}
