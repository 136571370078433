import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { apiURL } from "../../constants/constants";
import { getUserToken } from "../../constants/utils";

import Loader from "../Loader";
import OverviewLink from "../OverviewLink";
import SocialOverview from "./SocialOverview";
import SocialStats from "./SocialStats";

export default function Overview({ setTab }) {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [userProfile, setUserProfile] = useState({});
    const [callDates, setCallDates] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userToken = getUserToken();

        fetch(`${apiURL}/dashboard/user-profile/`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + userToken,
            },
        })
        .then(resp => {
            if (resp.status == 401) {
                navigate('/logout');
            }
            return resp.json();
        })
        .then(data => {
            //console.log(data);
            setUserProfile(data[0] || {});
            setCallDates(data[0].call_dates);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [])

    function handleClick(event, tab) {
        event.preventDefault();
        navigate('/dashboard/'+tab);
    }

    function formatDate(date) {
        return new Date(date).toLocaleDateString()
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            {userProfile && userProfile.live ?
                <>
                    <h2>View Your Stats</h2>
                    <SocialStats />
                </>
            :
                <>
                    <h2>Overview</h2>
                    <div>
                        {callDates?.date_test_shoot &&
                            <p className="disclaimer alert">You have booked a Test Shoot on {formatDate(callDates.date_test_shoot)}</p>
                        }
                        {callDates?.date_live_shoot &&
                            <p className="disclaimer alert">You have booked a Live Shoot on {formatDate(callDates.date_live_shoot)}</p>
                        }
                        <ul className="overview-links">
                            <li>
                                <h3>Information</h3>
                            <OverviewLink
                                handleClick={(e)=>{handleClick(e, 'generalDetails')}}
                                label={'General Details'}
                                completed={Boolean(userProfile['general_info'])}
                            />
                            <OverviewLink
                                handleClick={(e)=>{handleClick(e, 'contactDetails')}}
                                label={'Contact Details'}
                                completed={Boolean(userProfile['contact_info'])}
                            />
                            <OverviewLink
                                handleClick={(e)=>{handleClick(e, 'socialDetails')}}
                                label={'Social Details'}
                                completed={Boolean(userProfile['social_info'])}
                            />
                            <OverviewLink
                                handleClick={(e)=>{handleClick(e, 'bodyDetails')}}
                                label={'Body Details'}
                                completed={Boolean(userProfile['body_info'])}
                            />
                            <OverviewLink
                                handleClick={(e)=>{handleClick(e, 'bankDetails')}}
                                label={'Bank Details'}
                                completed={Boolean(userProfile['has_bank_info'])}
                            />
                            </li>
                            <li>             
                            <h3>Uploads</h3>     
                            <OverviewLink
                                handleClick={(e)=>{handleClick(e, 'uploadID')}}
                                label={'Upload ID Document'}
                                completed={Boolean(userProfile['has_id_file'])}
                            />
                            <OverviewLink
                                handleClick={(e)=>{handleClick(e, 'uploadPhotos')}}
                                label={'Upload Photos'}
                                completed={Boolean(
                                    userProfile['has_photo_face'] &&
                                    userProfile['has_photo_body'] &&
                                    userProfile['has_photo_frontal'] &&
                                    userProfile['has_photo_additional']
                                )}
                            />
                            <h3>Onboarding</h3>     
                            <OverviewLink
                                handleClick={(e)=>{handleClick(e, 'bookCall')}}
                                label={'Intro Call'}
                                completed={userProfile['call_info_done']}
                                disabled={userProfile['call_info_done']}
                            />
                            <OverviewLink
                                handleClick={(e)=>{handleClick(e, 'bookCall')}}
                                label={'Test Shoot'}
                                completed={userProfile['call_test_done']}
                                disabled={!userProfile['call_info_done'] || userProfile['call_test_done']}
                            />
                            <h3>Contract</h3>
                            <OverviewLink
                                handleClick={(e)=>{e.preventDefault()}}
                                label={userProfile['contract_status'] == 'sent' ? 'Contract Sent' : userProfile['contract_status'] == 'signed' ? 'Contract Signed' : 'Complete Above Sections'}
                                completed={userProfile['contract_status'] == 'signed'}
                                disabled={true}
                            />
                            <h3>Live Shoot</h3>
                            <OverviewLink
                                handleClick={(e)=>{handleClick(e, 'bookCall')}}
                                label={'Live Shoot'}
                                completed={userProfile['call_live_done']}
                                disabled={(!userProfile['call_test_done'] || userProfile['call_live_done']) || userProfile['contract_status'] != 'signed'}
                            />
                            </li>
                        </ul>
                    </div>
                </>
            }
        </div>
    );
}