export default function NumberInput({ name, setter, value, placeholder, label, min, max, disabled }) {

    return (
        <div className="numberFieldContainer">
            <input
                className="formTextField"
                type="number"
                name={name}
                id={name}
                onChange={(e) => setter(e.target.value)}
                value={value}
                placeholder={placeholder}
                min={min}
                max={max}
                disabled={disabled}
            />
            <small> {label}</small>
        </div>
    );
}