export default function SelectInput({ choices, name, value, setter, placeholder, disabled }) {
    return (
        <div className="selectInput">
            <select className="dropDown formTextField"
                name={name}
                id={name}
                onChange={(e) => setter(e.target.value)}
                defaultValue={value}
                disabled={disabled}
            >
                <option value="" disabled>
                    {placeholder}
                </option>
                
                {choices.map((choice, index)=>{
                    return (
                        <option key={index} value={choice.value}>{choice.label}</option>
                    )
                })}
            </select>
        </div>
    );
}