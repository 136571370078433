import { useEffect, useState } from "react";
import { calendlyURLs } from "../../constants/utils";

export default function BookCall({ userProfile }) {
    const [url, setURL] = useState('');

    useEffect(() => {
        if (!userProfile) {
            return;
        }   
        
        if (!userProfile.call_info_done) {
            setURL(calendlyURLs.introCall);
        } else if (userProfile.call_info_done && (!userProfile.call_test_done || !userProfile.call_live_done)) {
            setURL('TEST OR LIVE SHOOT REQUIRED');
        } else {
            setURL('');
        }
        /*} else if (!userProfile.call_test_done) {
            setURL(calendlyURLs.testShoot);
        } else if (!userProfile.call_live_done && userProfile.contract_status == 'signed') {
            setURL(calendlyURLs.liveShoot);
        }
        */
    }, [userProfile])

    return (
        <div>
            <h2>Bookings</h2>
            <div className="tab-book-call">
                {url ?
                    url == 'TEST OR LIVE SHOOT REQUIRED' ?
                        <p className="disclaimer" style={{ textAlign:'center' }}>Our team will contact you directly via WhatsApp to schedule your next booking with us</p>
                    :
                        <iframe src={url} frameBorder="0"></iframe>
                :
                <p className="disclaimer" style={{ textAlign:'center' }}>You don't need to book any calls at the moment</p>
                }
            </div>
        </div>
    );
}




    

    