import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { accentColor } from "../constants/constants";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

export default function OverviewLink({
  label,
  handleClick,
  completed,
  disabled = false,
}) {
  return (
    <a
      href="#"
      className={"overview-link" + (disabled ? " disabled" : "")}
      onClick={handleClick}
    >
      <span className="overview-link-text">
        {completed ? 
        <FontAwesomeIcon icon={faCheckCircle} color={'black'} size={'lg'} />
        :
        <FontAwesomeIcon icon={faCircle} color={'grey'} size={'lg'} />
        }
        {/*<small className="overview-icon"><p>{completed ? "✓" : ""}</p></small>*/}
        <span className="overview-link-label">{label}</span>
      </span>
    </a>
  );
}
