import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiURL } from "../../constants/constants";
import { getUserToken } from "../../constants/utils";
import BottomNavButton from "../BottomNavButton";
import ButtonSubmit from "../ButtonSubmit";
import Loader from "../Loader";
import MessageDisplay from "../MessageDisplay";
import TextInput from "../TextInput";

export default function BankDetails({ setTab }) {
    const navigate = useNavigate();
    
    const [bankName, setBankName] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountNo, setAccountNo] = useState('');
    const [sortCode, setSortCode] = useState('');
    const [hasBankInfo, setHasBankInfo] = useState(false);
    
    const [disabled, setDisabled] = useState(false);
    const [message, setMessage] = useState({type:'', body:''});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userToken = getUserToken();

        fetch(`${apiURL}/dashboard/user-profile/`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + userToken,
            },
        })
        .then(resp => {
            if (resp.status == 401) {
                navigate('/logout');
            }
            return resp.json();
        })
        .then(data => {
            //console.log(data);
            const userProfile = data[0];
            setDisabled(userProfile.edit_allowed ? false : true);
            setHasBankInfo(userProfile.has_bank_info);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [])

    function handleSubmit(e) {
        e.preventDefault();

        const userToken = getUserToken();
        const data = {
            bankName: bankName,
            accountName: accountName,
            accountNo: accountNo,
            sortCode: sortCode
        }

        setMessage({type:'', body:'Submitting...'});
        fetch(`${apiURL}/dashboard/user-bank-info/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + userToken
            },
            body: JSON.stringify(data)
        })
        .then(resp => {
            if (!resp.ok) {
                throw new Error('Submission Failed');
            }
            return resp.json()
        })
        .then(data => {
            //console.log(data);
            setHasBankInfo(true);
            setMessage({type:'success', body:'Submitted Successfully'});
        })
        .catch((error) => {
            console.error('Error:', error);
            setMessage({type:'warning', body:'Submission Failed'});
        });
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            <h2>Bank Details</h2>
            <div>
                <p className="message-warning">
                    {hasBankInfo ? 'Submitting again will overwrite your existing details' : ''}
                </p>
                <form method="POST" onSubmit={(e) => handleSubmit(e)}>
                    <TextInput
                        name={'bankName'}
                        placeholder={'Bank Name'}
                        setter={setBankName}
                        value={bankName}
                        disabled={disabled}
                    />
                    <TextInput
                        name={'accountName'}
                        placeholder={'Account Name'}
                        setter={setAccountName}
                        value={accountName}
                        disabled={disabled}
                    />
                    <TextInput
                        name={'accountNo'}
                        placeholder={'Account No'}
                        setter={setAccountName}
                        value={accountNo}
                        disabled={disabled}
                    />
                    <TextInput
                        name={'sortCode'}
                        placeholder={'Sort Code'}
                        setter={setSortCode}
                        value={sortCode}
                        disabled={disabled}
                    />
                    <MessageDisplay message={message} />
                    <ButtonSubmit handleClick={handleSubmit} disabled={disabled} />

                    <div className="bottomNavBtns">
                        <BottomNavButton label={'Back'} tab={'bodyDetails'} />
                        <BottomNavButton label={'Next'} tab={'uploadID'} />
                    </div>
                </form>
            </div>
        </div>
    );
}