export default function TextInput({ setter, name, placeholder, value, disabled }) {

    return (
        <input className="formTextField textField"
            type="text"
            name={name}
            id={name}
            placeholder={placeholder}
            onChange={e => setter(e.target.value)}
            defaultValue={value}
            disabled={disabled}
        />
    )
}