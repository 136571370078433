import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiURL } from "../../constants/constants";
import { createImgEl, getUserToken, validateFile, validateResolution } from "../../constants/utils";
import BottomNavButton from "../BottomNavButton";
import ButtonSubmit from "../ButtonSubmit";
import FileInput from "../FileInput";
import Loader from "../Loader";
import MessageDisplay from "../MessageDisplay";

export default function UploadID({ setTab }) {
    const navigate = useNavigate();
    const inpRef = useRef();
    
    const [fileExists, setFileExists] = useState(false);
    const [idFile, setIDFile] = useState();
    const [fileName, setFileName] = useState('');

    const [disabled, setDisabled] = useState(false);
    const [message, setMessage] = useState({type:'', body:''});
    const [loading, setLoading] = useState(true);
    const formEl = useRef();

    useEffect(() => {
        const userToken = getUserToken();

        fetch(`${apiURL}/dashboard/user-profile/`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + userToken,
            },
        })
        .then(resp => {
            if (resp.status == 401) {
                navigate('/logout');
            }
            return resp.json();
        })
        .then(data => {
            //console.log(data);
            const userProfile = data[0];
            setDisabled(userProfile?.edit_allowed ? false : true);
            setFileExists(userProfile.has_id_file ? true : false);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [])

    function handleClick(e) {
        e.preventDefault();
        inpRef.current.click();
    }

    async function handleSubmit(e) {
        e.preventDefault();

        const maxSize = 10000000; //10MB
        const minWidth = 500; //px
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const validationResult = await validateFile(idFile, acceptedTypes, maxSize, minWidth);
        if (validationResult != 'success') {
            setMessage({type:'warning', body:validationResult});
            return;
        };

        const userToken = getUserToken();

        const formData = new FormData();
        formData.append('idFile', idFile);

        setMessage({type:'', body:'Uploading...'});
        fetch(`${apiURL}/dashboard/user-upload-id/`, {
            method: 'POST',
            headers: {
                Authorization: 'Token ' + userToken
            },
            body: formData
        })
        .then(resp => {
            if (!resp.ok) {
                throw new Error('Upload Failed');
            }
            return resp.json();
        })
        .then(data => {
            //console.log(data);
            setFileExists(true);
            setMessage({type:'success', body:'Upload Successful'});
        })
        .catch((error) => {
            console.error('Error:', error);
            setMessage({type:'success', body:'Upload Failed'});
        });
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            <h2>Government-Issued ID</h2>
            <div>
                <p className="message-warning">
                    {fileExists ? "You have already uploaded a file. Submitting again will overwrite the existing one." : ""}
                </p>
                <form ref={formEl} method="POST" onSubmit={(e) => handleSubmit(e)}>
                    <FileInput
                        name={'id_file'}
                        title={'ID Document'}
                        file={idFile}
                        setFile={setIDFile}
                        accept={'application/pdf,image/*'}
                        disabled={disabled}
                    />
                    <MessageDisplay message={message} />
                    <ButtonSubmit handleClick={handleSubmit} disabled={disabled} />
                </form>

                <div className="bottomNavBtns">
                    <BottomNavButton label={'Back'} tab={'bankDetails'} />
                    <BottomNavButton label={'Next'} tab={'uploadPhotos'} />
                </div>
            </div>
        </div>
    );
}