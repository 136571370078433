export const calendlyURLs = {
    introCall: 'https://calendly.com/lustre-mgmt/intro-call',
    testShoot: 'https://calendly.com/lustre-mgmt/test-shoot',
    liveShoot: 'https://calendly.com/lustre-mgmt/live-shoot',
}

export const apiURL = 'https://apply.lustremgmt.com/dj/api'; //'http://127.0.0.1:8000/dj/api';

export const accentColor = '#b21e1e';

export const genderChoices = [
    {value: 'M', label: 'Male'},
    {value: 'F', label: 'Female'},
    {value: 'TMM', label: 'Transgender Male'},
    {value: 'TMF', label: 'Transgender Female'},
    {value: 'NC', label: 'Gender Variant/Non-Conforming'},
    {value: 'NL', label: 'Other/Not Listed'},
    {value: 'NA', label: 'Prefer Not to Say'},
]

export const orientationChoices = [
    {value: 'HE', label: 'Heterosexual/StraightMale'},
    {value: 'HO', label: 'Gay/Homosexual'},
    {value: 'BI', label: 'Bisexual'},
    {value: 'NL', label: 'Other/Not Listed'},
    {value: 'NA', label: 'Prefer Not to Say'},
]

export const roleChoices = [
    {value: 'TOP', label: 'Top'},
    {value: 'BOT', label: 'Bottom'},
    {value: 'VERS', label: 'Versatile'},
    {value: 'NA', label: 'Prefer Not to Say'},
]

export const bodyTypeChoices = [
    {value: 'ATH', label: 'Athletic'},
    {value: 'MUSC', label: 'Muscular'},
    {value: 'SLI', label: 'Slim'},
    {value: 'AVG', label: 'Average'},
]