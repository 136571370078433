import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiURL } from "../../constants/constants";
import { getUserToken } from "../../constants/utils";
import Loader from "../Loader";
import TextInput from "../TextInput";
import ButtonSubmit from "../ButtonSubmit";
import MessageDisplay from "../MessageDisplay";
import BottomNavButton from "../BottomNavButton";

export default function SocialDetails({ setTab }) {
    const navigate = useNavigate();
    
    const [userProfile, setUserProfile] = useState({});
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [message, setMessage] = useState({type:'', body:''});
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userToken = getUserToken();

        fetch(`${apiURL}/dashboard/user-profile/`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + userToken,
            },
        })
        .then(resp => {
            if (resp.status == 401) {
                navigate('/logout');
            }
            return resp.json();
        })
        .then(data => {
            //console.log(data);
            const userProfile = data[0];
            setUserProfile(userProfile);
            setDisabled(userProfile?.edit_allowed ? false : true);
            setFacebook(userProfile.social_info?.facebook);
            setInstagram(userProfile.social_info?.instagram);
            setTwitter(userProfile.social_info?.twitter);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [])

    function handleSubmit(e) {
        e.preventDefault();

        const userToken = getUserToken();
        const data = {
            facebook: facebook,
            instagram: instagram,
            twitter: twitter,
        }

        fetch(`${apiURL}/dashboard/user-social-info/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + userToken
            },
            body: JSON.stringify(data)
        })
        .then(resp => {
            if (!resp.ok) {
                throw new Error("Submission Failed");
            }
            return resp.json();
        })
        .then(data => {
            //console.log(data);
            setMessage({type:'success', body:'Submission Successful'});
        })
        .catch((error) => {
            console.error('Error:', error);
            setMessage({type:'warning', body:'Submission Failed'});
        });
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            <h2>Social Details</h2>
            <div>
                <form method="POST" onSubmit={(e) => handleSubmit(e)}>
                    <TextInput
                        name={'facebook'}
                        placeholder={'Facebook'}
                        setter={setFacebook}
                        value={facebook}
                        disabled={disabled}
                    />
                    <TextInput
                        name={'instagram'}
                        placeholder={'Instagram'}
                        setter={setInstagram}
                        value={instagram}
                        disabled={disabled}
                    />
                    <TextInput
                        name={'twitter'}
                        placeholder={'Twitter'}
                        setter={setTwitter}
                        value={twitter}
                        disabled={disabled}
                    />
                    <MessageDisplay message={message} />
                    <ButtonSubmit handleClick={handleSubmit} disabled={disabled}/>
                </form>
                <div className="bottomNavBtns">
                    <BottomNavButton label={'Back'} tab={'contactDetails'} />
                    <BottomNavButton label={'Next'} tab={'bodyDetails'} />
                </div>
            </div>
        </div>
    );
}