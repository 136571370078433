import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function PasswordInput({ setPassword, name, placeholder }) {
  const [visible, setVisible] = useState(false);

  return (
    <>
        <input
            type={visible ? "text" : "password"}
            name={name}
            id={name}
            placeholder={placeholder}
            onChange={(e) => setPassword(e.target.value)}
        />
        <span className="iconVisible" onClick={() => setVisible(!visible)}>
            {visible ?
                <FontAwesomeIcon icon={faEye} size={'xs'} />
            :
                <FontAwesomeIcon icon={faEyeSlash} size={'xs'} />
            }
        </span>
    </>
  );
}
