export default function NotFound() {
    return (
        <div style={{
            color: 'white',
            textAlign: 'center',
        }}>
            <h1>404</h1>
            <p>This page does not exist</p>
        </div>
    )
}