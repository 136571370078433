import { useEffect, useRef, useState } from "react";
import CountryInput from "../CountryInput";
import TextInput from "../TextInput";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUserToken } from "../../constants/utils";
import { apiURL, genderChoices, orientationChoices } from "../../constants/constants";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import ButtonSubmit from "../ButtonSubmit";
import MessageDisplay from "../MessageDisplay";
import SelectInput from "../SelectInput";
import BottomNavButton from "../BottomNavButton";

export default function GeneralDetails({ setTab }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState({type:'', body:''});
    const [disabled, setDisabled] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [stageName, setStageName] = useState("");
    const [dob, setDOB] = useState();// useState(new Date('2004-01-01'));
    const [gender, setGender] = useState("");
    const [orientation, setOrientation] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [about, setAbout] = useState("");

    useEffect(() => {
        const userToken = getUserToken();

        fetch(`${apiURL}/dashboard/user-profile/`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + userToken,
            },
        })
        .then(resp => {
            if (resp.status == 401) {
                navigate('/logout');
            }
            return resp.json();
        })
        .then(data => {
            //console.log(data);
            const userProfile = data[0];
            setDisabled(userProfile.edit_allowed ? false : true);
            setFirstName(userProfile.general_info?.first_name);
            setLastName(userProfile.general_info?.last_name);
            setStageName(userProfile.general_info?.stage_name);
            if(userProfile.general_info?.dob) {
                setDOB(new Date(userProfile.general_info?.dob));
            }
            setGender(userProfile.general_info?.gender || '');
            setOrientation(userProfile.general_info?.orientation || '');
            setCity(userProfile.general_info?.city);
            setCountry(userProfile.general_info?.country || '');
            setAbout(userProfile.general_info?.about);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [])

    function handleSubmit(e) {
        e.preventDefault();

        const userToken = getUserToken();
        const data = {
            firstName: firstName,
            lastName: lastName,
            stageName: stageName,
            dob: dob.toLocaleDateString("en-GB"),
            gender: gender,
            orientation: orientation,
            city: city,
            country: country,
            about: about,
        };

        fetch(`${apiURL}/dashboard/user-general-info/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + userToken
            },
            body: JSON.stringify(data)
        })
        .then(data => data.json())
        .then(data => {
            //console.log(data);
            setMessage({type:'success', body:'Submission Successful'});
        })
        .catch((error) => {
            console.error('Error:', error);
            setMessage({type:'warning', body:'Submission Failed'});
        });
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            <h2>General Details</h2>
            <div>
                <form method="POST" onSubmit={(e) => handleSubmit(e)}>
                    <TextInput  
                        name={"first_name"}
                        placeholder={"First Name"}
                        setter={setFirstName}
                        value={firstName}
                        disabled={disabled}
                    />
                    <TextInput 
                        name={"last_name"}
                        placeholder={"Last Name"}
                        setter={setLastName}
                        value={lastName}
                        disabled={disabled}
                    />
                    {/*<TextInput 
                        name={"stage_name"}
                        placeholder={"Stage Name"}
                        setter={setStageName}
                        value={stageName}
                        disabled={disabled}
                    />*/}

                    <DatePicker
                        closeOnScroll={false}
                        selected={dob}
                        onChange={(date) => setDOB(date)}
                        placeholderText={'Date of Birth'} 
                        minDate={new Date("01-01-1922")}
                        maxDate={new Date("01-01-2004")}
                        showMonthDropdown
                        showYearDropdown
                        peekNextMonth
                        dropdownMode="select"
                        disabled={disabled}
                    />

                    <SelectInput
                        placeholder={'Gender'}
                        name={'orientation'}
                        choices={genderChoices}
                        value={gender}
                        setter={setGender}
                        disabled={disabled}
                    />

                    <SelectInput
                        placeholder={'Orientation'}
                        name={'orientation'}
                        choices={orientationChoices}
                        value={orientation}
                        setter={setOrientation}
                        disabled={disabled}
                    />

                    <CountryInput setter={setCountry} value={country} disabled={disabled} />

                    <TextInput name={"city"} placeholder={"City"} setter={setCity} value={city} disabled={disabled} />

                    <textarea className="formTextField"
                        name="about"
                        id="about"
                        cols="30" 
                        rows="10"
                        placeholder="About your interests"
                        onChange={(e) => setAbout(e.target.value)}
                        value={about}
                        disabled={disabled}
                    ></textarea>

                    <MessageDisplay message={message} />

                    <ButtonSubmit handleClick={handleSubmit} disabled={disabled} />
                </form>
                <div className="bottomNavBtns">
                    <BottomNavButton label={'Back'} tab={'overview'} />
                    <BottomNavButton label={'Next'} tab={'contactDetails'} />
                </div>
            </div>
        </div>
    );
}