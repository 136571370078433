import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiURL } from "../constants/constants";
import MessageDisplay from "./MessageDisplay";
import PasswordInput from "./PasswordInput";

export default function LoginForm() {
    const [pwVisible, setPWVisible] = useState();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState({type:'', body:''});
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();

        setMessage({type:'', body:'Logging in...'});
        fetch(`${apiURL}/users/token-auth/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: username,
                password: password,
            }),
        })
        .then((resp) => {
            if (!resp.ok) {
                throw new Error('Submission Failed');
            }
            return resp.json()
        })
        .then((data) => {
            console.log(data);
            if (!data.token) {
                throw new Error('Login Failed');
            }
            localStorage.setItem("userToken", data.token);
            navigate("/dashboard");
        })
        .catch((error) => {
            console.error('Error:', error);
            setMessage({type:'warning', body:'Login Failed'});
        });
    }

    return (
        <div className="authPage">
            <img className="logo"
                src={require("../assets/images/cropped-Lustre-MGMT-Colour.png")}
            />
            <h1>Login</h1>
            <form className="authForm" onSubmit={(e) => handleSubmit(e)}>
                <div className="textField">
                    <input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Username"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>

                <div className="password textField">
                    <PasswordInput
                        setPassword={setPassword}
                        name={"password"}
                        placeholder={'Password'}
                    />
                </div>

                <MessageDisplay message={message} />
                <input className="button" type="submit" value="Login" />
            </form>

            <p className="noAccountContainer">
                Don't have an account?
                <a className="link" onClick={() => navigate('/signup')}>Sign Up</a>
            </p>
        </div>
    );
}
