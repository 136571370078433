import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiURL, bodyTypeChoices, roleChoices } from "../../constants/constants";
import { getUserToken } from "../../constants/utils";
import BottomNavButton from "../BottomNavButton";
import ButtonSubmit from "../ButtonSubmit";
import Loader from "../Loader";
import MessageDisplay from "../MessageDisplay";
import NumberInput from "../NumberInput";
import SelectInput from "../SelectInput";

export default function BodyDetails({ setTab }) {
    const navigate = useNavigate();

    const [height, setHeight] = useState(0);
    const [weight, setWeight] = useState(0);
    const [footSize, setFootSize] = useState(0);
    const [bodyType, setBodyType] = useState('');
    const [role, setRole] = useState('');
    const [message, setMessage] = useState({type:'', body:''});
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userToken = getUserToken();

        fetch(`${apiURL}/dashboard/user-profile/`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + userToken,
            },
        })
        .then(resp => {
            if (resp.status == 401) {
                navigate('/logout');
            }
            return resp.json();
        })
        .then(data => {
            //console.log(data);
            const userProfile = data[0];
            setDisabled(userProfile.edit_allowed ? false : true);
            setHeight(Number.parseInt(userProfile.body_info?.height));
            setWeight(Number.parseInt(userProfile.body_info?.weight));
            setFootSize(Number.parseFloat(userProfile.body_info?.foot_size));
            setBodyType(userProfile.body_info?.body_type || '');
            setRole(userProfile.body_info?.role || '');
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [])

    function handleSubmit(e) {
        e.preventDefault();
        const userToken = getUserToken();

        const data = {
            height: height,
            weight: weight,
            footSize: footSize,
            bodyType: bodyType,
            role: role,
        }
        console.log('Test', data);

        fetch(`${apiURL}/dashboard/user-body-info/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + userToken
            },
            body: JSON.stringify(data)
        })
        .then(resp => {
            if (!resp.ok) {
                throw new Error('Submission Failed');
            }
            return resp.json()
        })
        .then(data => {
            console.log(data);
            setMessage({type:'success', body:'Submitted Successfully'});
        })
        .catch((error) => {
            console.error('Error:', error);
            setMessage({type:'warning', body:'Submitted Failed'});
        });
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            <h2>Body Details</h2>
            <div>
                <form method="POST" onSubmit={(e) => handleSubmit(e)}>
                    <NumberInput
                        name={'height'}
                        value={height}
                        setter={setHeight}
                        placeholder={'__'}
                        label={'Height (cm)'}
                        min={100}
                        disabled={disabled}
                    />

                    <NumberInput
                        name={'weight'}
                        value={weight}
                        setter={setWeight}
                        placeholder={'__'}
                        label={'Weight (kg)'}
                        min={30}
                        disabled={disabled}
                    />

                    <NumberInput
                        name={'foot_size'}
                        value={footSize}
                        setter={setFootSize}
                        placeholder={'__'}
                        label={'Foot Size (UK)'}
                        min={3}
                        disabled={disabled}
                    />

                    <SelectInput
                        placeholder={'Body Type'}
                        name={'body_type'}
                        choices={bodyTypeChoices}
                        value={bodyType}
                        setter={setBodyType}
                        disabled={disabled}
                    />

                    <SelectInput
                        placeholder={'Role'}
                        name={'role'}
                        choices={roleChoices}
                        value={role}
                        setter={setRole}
                        disabled={disabled}
                    />

                    <MessageDisplay message={message} />

                    <ButtonSubmit handleClick={handleSubmit} disabled={disabled}/>

                    <div className="bottomNavBtns">
                        <BottomNavButton label={'Back'} tab={'socialDetails'} />
                        <BottomNavButton label={'Next'} tab={'bankDetails'} />
                    </div>
                </form>
            </div>
        </div>
    );
}