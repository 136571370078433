import logo from './logo.svg';
import './App.css';
import Login from './pages/Login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Logout from './pages/Logout';
import Dashboard from './pages/Dashboard';
import Signup from './pages/Signup';
import NotFound from './pages/NotFound';
import { ChakraProvider } from '@chakra-ui/react'

function App() {
  return (
  
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Dashboard />} index />
          <Route exact path="dashboard/" element={<Dashboard />} />

          <Route key='tabOverview' exact path="/dashboard/overview" element={<Dashboard initTab={'overview'} />} />
          <Route key='tabGeneralDetails' exact path="/dashboard/generalDetails" element={<Dashboard initTab={'generalDetails'} />} />
          <Route key='tabContactDetails' exact path="/dashboard/contactDetails" element={<Dashboard initTab={'contactDetails'} />} />
          <Route key='tabSocialDetails' exact path="/dashboard/socialDetails" element={<Dashboard initTab={'socialDetails'} />} />
          <Route key='tabBodyDetails' exact path="/dashboard/bodyDetails" element={<Dashboard initTab={'bodyDetails'} />} />
          <Route key='tabBankDetails' exact path="/dashboard/bankDetails" element={<Dashboard initTab={'bankDetails'} />} />
          <Route key='tabUploadID' exact path="/dashboard/uploadID" element={<Dashboard initTab={'uploadID'} />} />
          <Route key='tabUploadPhotos' exact path="/dashboard/uploadPhotos" element={<Dashboard initTab={'uploadPhotos'} />} />
          <Route key='tabBookCall' exact path="/dashboard/bookCall" element={<Dashboard initTab={'bookCall'} />} />
          
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="logout" element={<Logout />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
