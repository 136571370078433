import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

export default function BottomNavButton({ label, tab}) {
    const navigate = useNavigate();

    return (
        <a
            className="btnBottomNav"
            href="#"
            onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard/'+tab);
            }}
        >
            {label == 'Back' ?
                <span>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    {' ' + label}
                </span>
            :
                <span>
                    {label + ' '}
                    <FontAwesomeIcon icon={faChevronRight} />
                </span>
            }
        </a>
    )
}