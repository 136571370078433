import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPhone, faPencil } from '@fortawesome/free-solid-svg-icons'
import { accentColor } from "../constants/constants";

export default function DashboardSidebar({ setTab, tab }) {
  const [menuVisible, setVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <nav className="nav">
      <ul className="navMenuContainer">
        <li className={`tabText ${tab === "overview" ? "tabTextActive" : ""}`} onClick={() => navigate('/dashboard/'+'overview')}>
          <span><FontAwesomeIcon icon={faEye} color={accentColor} size="xs" /></span> Overview
        </li>
        <li className={`tabText ${tab === "bookCall" ? "tabTextActive" : ""}`} onClick={() => navigate('/dashboard/'+'bookCall')}>
          <span><FontAwesomeIcon icon={faPhone} color={accentColor} size="xs" /></span> Bookings
        </li>
        {/*
        <li className={`tabText ${tab === "bookTestShoot" ? "tabTextActive" : ""}`} onClick={() => setTab("bookTestShoot")}>
          <span>✆</span> Book a Test Shoot
        </li>
        <li className={`tabText ${tab === "bookLiveShoot" ? "tabTextActive" : ""}`} onClick={() => setTab("bookLiveShoot")}>
            <span>✆</span> Book a Live Shoot
        </li>
        */}
        <li className={`editProfileDropdown tabText ${tab != "bookCall" && tab != 'overview' ? "tabTextActive" : ""}`}>
          <button className="dropbtn">
            <span><FontAwesomeIcon icon={faPencil} color={accentColor} size="xs" /></span> Profile
          </button>
          <div className="editProfileDropdownContent">
            <a className={`${tab === "generalDetails" ? "tabTextActive" : ""}`} onClick={() => navigate('/dashboard/'+'generalDetails')}>General Details</a>
            <a className={`${tab === "contactDetails" ? "tabTextActive" : ""}`} onClick={() => navigate('/dashboard/'+'contactDetails')}>Contact Details</a>
            <a className={`${tab === "socialDetails" ? "tabTextActive" : ""}`} onClick={() => navigate('/dashboard/'+'socialDetails')}>Social Details</a>
            <a className={`${tab === "bodyDetails" ? "tabTextActive" : ""}`} onClick={() => navigate('/dashboard/'+'bodyDetails')}>Body Details</a>
            <a className={`${tab === "bankDetails" ? "tabTextActive" : ""}`} onClick={() => navigate('/dashboard/'+'bankDetails')}>Bank Details</a>
            <a className={`${tab === "uploadID" ? "tabTextActive" : ""}`} onClick={() => navigate('/dashboard/'+'uploadID')}>Upload ID</a>
            <a className={`${tab === "uploadPhotos" ? "tabTextActive" : ""}`} onClick={() => navigate('/dashboard/'+'uploadPhotos')}>Upload Photos</a>
          </div>
        </li>
      </ul>
    </nav>
  );
}
