import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import { getUserToken } from "../constants/utils";

export default function Login() {
    const navigate = useNavigate();

    useEffect(() => {
        const userToken = getUserToken();
        if (userToken) {
            navigate('/dashboard');
        }
        /* TODO: Add back after setting up api auth
        if (userToken) {
            fetch('/api/users/validate-token', {
                method: "GET",
                //crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "Authorization": "Token " + userToken,
                },
            })
            .then((response) => {
                if (response.status == 401) {
                    navigate("/logout");
                    return;
                }
                return response.json();
            })
            .then((data) => {
                navigate("/dashboard");
            })
            .catch((error) => {
                console.error("Error: ", error);
            });
        }
        */
    }, [])

    return (
        <div className="dashboard authPage">
            <LoginForm />
        </div>
    );
}