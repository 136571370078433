export const getUserToken = function() {
    return localStorage.getItem("userToken") || sessionStorage.getItem('userToken');
}

export const calendlyURLs = {
    introCall: 'https://calendly.com/lustre-mgmt/intro-call',
    testShoot: 'https://calendly.com/lustre-mgmt/test-shoot',
    liveShoot: 'https://calendly.com/lustre-mgmt/live-shoot',
}

export const createImgEl = function(url) {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img.width);
        img.onerror = reject;
        img.src = url;
    })
}

export const validateResolution = async function(file, minWidth) {
    if (file.type.includes('image')) {
        const url = URL.createObjectURL(file);
        const width = await createImgEl(url);
        if (width < minWidth) {
            return false;
        }
    }
    return true;
}

export const validateFile = async function(file, acceptedTypes, maxSize, minWidth) {
    if (maxSize && file.size > maxSize) { //Check file size
        return 'File size too large';
    }
    if (acceptedTypes && !acceptedTypes.includes(file.type)) { //Check filetype
        return 'Unsupported file type';
    }
    if (minWidth && file.type.includes('image')) { //Check min resolution
        const resValid = await validateResolution(file, minWidth);
        if (!resValid) {
            return 'Image resolution too low';
        }
    }
    return 'success';
}