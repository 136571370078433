import { useRef } from "react";

export default function FileInput({ name, title, file, setFile, accept, className, disabled }) {
    const inpRef = useRef()

    function handleClick(e) {
        e.preventDefault();
        inpRef.current.click();
    }

    return (
        <div className="upload">
            <label htmlFor={name}>{title}</label>
            <input
                ref={inpRef} className={className}
                type="file" name={name} id={name}
                onChange={(e) => {setFile(e.target.files[0])}}
                accept={accept}
                disabled={disabled}
            />
            <p style={{textAlign:'center'}}>{file?.name ? 'File Selected: ' + file.name : 'No File Selected'}</p>
            <button className="btnSelectFile" onClick={handleClick}>Select File</button>
        </div>
    )
}