import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DashboardHeader from "../components/DashboardHeader";
import DashboardMain from "../components/DashboardMain";
import DashboardSidebar from "../components/DashboardSidebar";
import MobileDashboardSidebar from "../components/MobileDashboardSidebar";
import { getUserToken } from "../constants/utils";

export default function Dashboard({ initTab }) {
  const [tab, setTab] = useState(initTab || 'overview');
  const navigate = useNavigate();

  useEffect(() => {
    const userToken = getUserToken();
    if (!userToken) {
      navigate("/login");
    }
  });

  useEffect(() => {
    if (initTab != tab) {
      setTab(initTab);
    }
  })

  return (
    <div className="dashboard">
      <DashboardHeader />
      <DashboardSidebar tab={tab} setTab={setTab} />
      <MobileDashboardSidebar tab={tab} setTab={setTab} />
      <DashboardMain tab={tab} setTab={setTab} />
    </div>
  );
}
