import { faEye, faPencil, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { accentColor } from "../constants/constants";

export default function MobileDashboardSidebar({ setTab, tab }) {
  const navigate = useNavigate();
  let [menuVisible, setVisible] = useState(false);
  let [editMenuVisible, setEditVisible] = useState(false);

  function handleLinkClick(tab) {
    setVisible(false);
    setEditVisible(false);
    navigate('/dashboard/'+tab);
    //setTab(tab);
  }

  return (
    <div className="mobileNav">
      <button className={`dropButton ${menuVisible ? "dropButtonActive" : ""}`}
        onClick={() => setVisible((menuVisible ^= true))}>
        Dashboard
        <i className={`arrow ${menuVisible ? "down" : "right"}`}></i>
      </button>
      <ul className={`menuContainer ${menuVisible ? "menuContainerActive" : ""}`}>
        <li
          className={tab === "overview" ? "tabTextActive" : ""}
          onClick={() => handleLinkClick("overview")}
        >
          <span className="tabText">
            <span><FontAwesomeIcon icon={faEye} color={accentColor} size="xs" /></span> Overview
          </span>
        </li>
        <li
          className={tab === "bookCall" ? "tabTextActive" : ""}
          onClick={() => handleLinkClick("bookCall")}
        >
          <span className="tabText">
            <span><FontAwesomeIcon icon={faPhone} color={accentColor} size="xs" /></span> Bookings
          </span>
        </li>
        <li className="editProfileDropdown tabText">
          <button
            className="dropbtn"
            onClick={() => setVisible((menuVisible ^= true))}
            style={{padding:0}}
          >
              <span><FontAwesomeIcon icon={faPencil} color={accentColor} size="xs" /></span> Edit Profile
              <i className={`arrow ${editMenuVisible ? "down" : "right"}`}></i>
          </button>
          <div className={`menuContainer editProfileDropdownContent ${editMenuVisible ? "menuContainerActive" : ""}`}>
            <a
              className={tab === "generalDetails" ? "tabTextActive" : ""}
              onClick={() => handleLinkClick("generalDetails")}
            >
              General Details
            </a>
            <a 
              className={tab === "contactDetails" ? "tabTextActive" : ""}
              onClick={() => handleLinkClick("contactDetails")}
            >
              Contact Details
            </a>
            <a
              className={tab === "socialDetails" ? "tabTextActive" : ""}
              onClick={() => handleLinkClick("socialDetails")}
            >
              Social Details
            </a>
            <a
              className={tab === "bodyDetails" ? "tabTextActive" : ""}
              onClick={() => handleLinkClick("bodyDetails")}
            >
              Body Details
            </a>
            <a
              className={tab === "bankDetails" ? "tabTextActive" : ""}
              onClick={() => handleLinkClick("bankDetails")}
            >
              Bank Details
            </a>
            <a
              className={tab === "uploadID" ? "tabTextActive" : ""}
              onClick={() => handleLinkClick("uploadID")}
            >
              Upload ID
            </a>
            <a
              className={tab === "uploadPhotos" ? "tabTextActive" : ""}
              onClick={() => handleLinkClick("uploadPhotos")}
            >
              Upload Photos
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
}
